<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Officetime"
      title="Officetime"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Главная',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'Сотрудники', icon: 'ni ni-single-02 text-yellow', path: '/employees'}"/>
        <sidebar-item :link="{name: 'Отделы', icon: 'ni ni-single-02 text-blue', path: '/departments'}"/>
        <sidebar-item :link="{name: 'Рабочие периоды', icon: 'ni ni-calendar-grid-58 text-green', path: '/periods'}"/>
        <sidebar-item :link="{name: 'Производственный календарь', icon: 'ni ni-calendar-grid-58 text-pink', path: '/production-calendar'}"/>
        <sidebar-item :link="{name: 'Роутеры', icon: 'ni ni-laptop text-red', path: '/routers'}"/>
        <sidebar-item :link="{name: 'Настройки', icon: 'ni ni-settings-gear-65 text-black', path: '/settings'}"/>

<!--        <sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}"/>-->
<!--        <sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>-->
<!--        <sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>-->
<!--        <sidebar-item :link="{name: 'Tables', icon: 'ni ni-bullet-list-67 text-red', path: '/tables'}"/>-->
<!--        <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/login'}"/>-->
<!--        <sidebar-item :link="{name: 'Register', icon: 'ni ni-circle-08 text-pink', path: '/register'}"/>-->

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar :userName="userName"></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
        userName: this.$store.state.user.firstname,
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
