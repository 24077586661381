/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import Vuex from 'vuex'
import Axios from 'axios'
import store from './store'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(ArgonDashboard)
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$http = Axios;

Axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.common['Token'] = token;
      }
      return config;
    },
    error => {
      Promise.reject(error)
    });

Axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (error.response.config.url === '/token/refresh') {
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token_expires");
        localStorage.removeItem("access_token_expires");
        store.dispatch("logout")
        router.push('/login')
    }
    originalRequest._retry = true
    return Axios.post('/token/refresh',
        {
          "refresh_token": localStorage.getItem('refresh_token')
        })
        .then(res => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            localStorage.setItem('token', res.data.access_token)
            localStorage.setItem('refresh_token', res.data.refresh_token)
            //localStorage.setItem('access_token_expires', res.data.access_token_expires)
            //localStorage.setItem('refresh_token_expires', res.data.refresh_token_expires)

            // 2) Change Authorization header
            //axios.defaults.headers.common['Token'] = localStorageService.getAccessToken();
            Axios.defaults.headers.common['Token'] = res.data.access_token;
            originalRequest.headers['Token'] = res.data.access_token;

            // 3) return originalRequest object with Axios.
            error.response.config.headers['Token'] = res.data.access_token;
            //return axios(error.response.config);
            return Axios(originalRequest);
          }
        })
  }
});

Vue.use(VueYandexMetrika, {
    id: 72748870,
    router: router,
    env: process.env.NODE_ENV,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
})

Vue.use(VueAnalytics, {
    id: 'G-BRZWEW4HGR',
    checkDuplicatedScript: true
})

Vue.mixin({
    methods: {
        getCurrentTime: function () {
            // create Date object for current location
            let d = new Date();

            // convert to msec
            // add local time zone offset
            // get UTC time in msec
            let utc = d.getTime() + (d.getTimezoneOffset() * 60000);

            // create new Date object for different city
            // using supplied offset
            let nd = new Date(utc + (3600000 * 3));

            return nd.toLocaleDateString("fr-CA").slice(0, 10)
        },
        getProgressColor: function (complete) {
            if (complete >= 30 && complete < 70) {
                return 'danger';
            } else if (complete >= 70 && complete < 100) {
                return 'info';
            } else if (complete >= 100) {
                return 'success';
            }

            return 'warning'
        },
    },
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
