import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store.js'
Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next()
    return
  }
  next('/login')
}

let router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'Главная',
          beforeEnter: ifAuthenticated,
          /*meta: {
            requiresAuth: true
          },*/
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/employees',
          name: 'сотрудники',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Employees.vue')
        },
        {
          path: '/employees-time/:id/period/:period',
          name: 'рабочее время сотрудника',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/EmployeesTime.vue')
        },
        {
          path: '/departments',
          name: 'отделы',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Departments.vue')
        },
        {
          path: '/periods',
          name: 'периоды',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Periods.vue')
        },
        {
          path: '/production-calendar',
          name: 'Производственный календарь',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/ProductionCalendar.vue')
        },
        {
          path: '/settings',
          name: 'настройки',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Settings.vue')
        },
        {
          path: '/routers',
          name: 'роутеры',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Routers.vue')
        },
        {
          path: '/profile',
          name: 'Профиль',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/user-settings',
          name: 'Настройки аккаунта',
          beforeEnter: ifAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/UserSettings.vue')
        }
        /*{
          path: '/icons',
          name: 'icons',
          beforeEnter: ifAuthenticated,
          component: () => import(/!* webpackChunkName: "demo" *!/ './views/Icons.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          beforeEnter: ifAuthenticated,
          component: () => import(/!* webpackChunkName: "demo" *!/ './views/Maps.vue')
        },
        {
          path: '/tables',
          name: 'tables',
          beforeEnter: ifAuthenticated,
          component: () => import(/!* webpackChunkName: "demo" *!/ './views/Tables.vue')
        }*/
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          beforeEnter: ifNotAuthenticated,
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        }
      ]
    }
  ]
});

export default router