import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {
            'firstname': localStorage.getItem('user_firstname') || '',
            'surname': localStorage.getItem('user_surname') || '',
            'lastname': localStorage.getItem('user_lastname') || '',
            'birthDate': localStorage.getItem('user_birth_date') || '',
            'email': localStorage.getItem('user_email') || '',
            'position': localStorage.getItem('user_position') || '',
            'department': localStorage.getItem('user_department') || '',
        }
    },
    mutations: {
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token, user){
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/login', data: user, method: 'POST' })
                    .then(resp => {
                        const token = resp.data.access_token
                        const refreshToken = resp.data.refresh_token
                        let data = JSON.parse(b64DecodeUnicode(token.split('.')[1]))
                        //let data = JSON.parse(atob(token.split('.')[1]))

                        localStorage.setItem('token', token)
                        localStorage.setItem('refresh_token', refreshToken)
                        localStorage.setItem('user_firstname', data.userFirstname)
                        localStorage.setItem('user_surname', data.userSurname)
                        localStorage.setItem('user_lastname', unescape(decodeURIComponent(data.userLastname)))
                        localStorage.setItem('user_birth_date', data.userBirthDate)
                        localStorage.setItem('user_email', data.userEmail)
                        localStorage.setItem('user_department', data.userEmail)
                        axios.defaults.headers.common['Token'] = token
                        commit('auth_success', token, data.userEmail)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        register({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: '/register', data: user, method: 'POST' })
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}){
            return new Promise((resolve/*, reject*/) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Token']
                resolve()
            })
        }
    },
    getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
});